import React, { useEffect, useState } from "react";

import { Card, Table, Container, Row, Col, Pagination, Form } from "react-bootstrap";
import { ButtonBlock, PaginationBlock } from './styles'
import { apiUsers } from '../../services/api'
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux'
import { FaRegTrashAlt } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'; // Import

import ButtonCustom from '../../components/ButtonCustom';
import CreateUser from './CreateUser';

import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

function Users() {
    const endpointSelected = useSelector(state => state.auth.endpointSelected)

    const [users, setUsers] = useState([]);
    const [showUsers, setShowUsers] = useState([]);
    const [page, setPage] = useState(0)
    const [pages, setPages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [search, setSearch] = useState('');

    const SIZE = 20;

    const handleUsers = async () => {
        try {
            const response = await apiUsers.get('/users/endpoint', {
                params: {
                    endpoint: endpointSelected,
                }
            });
            console.log('response', response);

            const responseUser = response.data;

            setUsers(responseUser);
            setShowUsers(responseUser);

            const items = [];
            const sizePagination = (responseUser.length / SIZE) > (responseUser.length / SIZE).toFixed() ? (responseUser.length / SIZE) + 1 : (responseUser.length / SIZE);
            if (sizePagination > 1) {
                setPage(1)
                for (let number = 1; number <= sizePagination; number++) {
                    items.push(
                        number
                    );
                }
                console.log(items);
                setPages(items);
            }
        } catch (e) {
            console.log('Erro ao buscar usuários', e);
            toast.error('Houve um erro ao buscar usuários 😢')
        }
    }

    const removeUser = async (id, user) => {
        try {

            confirmAlert({
                title: 'Remover',
                closeOnClickOutside: true,
                message: `Tem certeza que deseja remover o usuário ${user}`,
                buttons: [
                    {
                        label: 'Sim',
                        onClick: async () => {
                            const response = await apiUsers.post(`/users/${id}/remove/endpoint`,
                                {
                                    endpoints: [endpointSelected]
                                });
                            await handleUsers();
                            setSearch('');
                            toast.success('Usuário removido com sucesso 😉')
                        }
                    },
                    {
                        label: 'Não',
                    }
                ]
            });
        } catch (e) {
            toast.error('Houve um erro ao remover o usuário 😢')
        }
    }

    const postUser = async () => {
        try {

            const response = await apiUsers.post('/users',
                {
                    email,
                    active: true,
                    endpoints: [endpointSelected]
                });
            await handleUsers();
            setSearch('');
            toast.success('Usuário cadastrado com sucesso 😉')
        } catch (e) {
            toast.error('Houve um erro ao cadastrar o usuário 😢')
        }
    }

    useEffect(() => {
        if (endpointSelected != null) {
            handleUsers();
        }
    }, [endpointSelected])

    const filterUsers = () => {

        const filteredUsers = [...users].filter((element) => { return search === '' || element.email.indexOf(search) !== -1 });
        setShowUsers(filteredUsers);

        const items = [];
        const sizePagination = (filteredUsers.length / SIZE) > (filteredUsers.length / SIZE).toFixed() ? (filteredUsers.length / SIZE) + 1 : (filteredUsers.length / SIZE);
        if (sizePagination > 1) {
            setPage(1)
            for (let number = 1; number <= sizePagination; number++) {
                items.push(
                    number
                );
            }
            console.log(items);
            setPages(items);
        }
    }

    useEffect(() => {
        filterUsers()
    }, [search])

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <ButtonBlock>
                            <ButtonCustom
                                className="btn-fill pull-right"
                                type="submit"
                                variant="info"
                                color="#000"
                                onClick={() => setShowModal(!showModal)}
                            >
                                Criar Usuário
                            </ButtonCustom>
                        </ButtonBlock>
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">Usuários</Card.Title>
                                <p className="card-category">
                                    Usuários cadastrados na plataforma
                                </p>
                                <Form.Control
                                    id="search"
                                    placeholder="Pesquisar"
                                    type="search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                ></Form.Control>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped" PAGI>
                                    <thead>
                                        <tr>
                                            <th className="border-0">E-mail</th>
                                            <th className="border-0">Data de Criação</th>
                                            <th className="border-0">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...showUsers].splice((SIZE * (page - 1)), SIZE).map((element) => {
                                            return (
                                                <tr>
                                                    <td>{element.email}</td>
                                                    <td>{element.createdAt}</td>
                                                    <td>{<FaRegTrashAlt size="24px" color="black" onClick={() => { removeUser(element.id, element.email) }} />}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>

                            </Card.Body>
                        </Card>
                        <PaginationBlock>
                            <Pagination>
                                {page !== 1 && (
                                    <>
                                        <Pagination.First onClick={() => { setPage(1) }} />
                                        <Pagination.Prev onClick={() => { setPage(page - 1) }} />
                                    </>
                                )}
                                {[...pages].splice((((page - 3) < 0) || (pages.length < 5) ? 0 : (((page - 3) + 5) > pages.length) ? (pages.length - 5) : page - 3), 5).map((number) => {
                                    return (<Pagination.Item key={number} active={number === page} onClick={(e) => {
                                        setPage(parseInt(e.target.outerText))
                                    }}>
                                        {number}
                                    </Pagination.Item>)
                                })}
                                {page !== pages.length &&
                                    (
                                        <>
                                            <Pagination.Next onClick={() => { setPage(page + 1) }} />
                                            <Pagination.Last onClick={() => { setPage(pages.length) }} />
                                        </>
                                    )}
                            </Pagination>
                        </PaginationBlock>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
            {showModal && (
                <CreateUser
                    onSubmit={postUser}
                    handleEmail={setEmail}
                    email={email}
                    handleModal={setShowModal}
                    showModal={showModal}
                />
            )}
        </>
    )
}


export default Users;