import axios from 'axios'
import variables from '../configs/variables'

export const apiAdmin = axios.create({
    baseURL: variables.API_ADMIN_URL,
    headers: {
        'x-api-key': variables.API_KEY
    }
})

export const apiUsers = axios.create({
    baseURL: variables.API_USERS_URL,
    headers: {
        'x-api-key': variables.API_KEY
    }
})

export const apiAnalytics = axios.create({
    baseURL: variables.API_ANALYTICS_URL,
    headers: {
        'x-api-key': variables.API_KEY
    }
})