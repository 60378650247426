/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "pages/Dashboard";
import UserProfile from "pages/UserProfile";
import TableList from "pages/TableList";
import Typography from "pages/Typography";
import Icons from "pages/Icons";
import Maps from "pages/Maps";
import Notifications from "pages/Notifications";
import Upgrade from "pages/Upgrade";
import Users from "pages/Users"

import { FaUsers, FaSignOutAlt, FaTachometerAlt } from 'react-icons/fa';

const dashboardRoutes = [
  
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: (<FaTachometerAlt size="16px" />),
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Usuários",
    icon: (<FaUsers size="16px" />),
    component: Users,
    layout: "/admin",
  },
  {
    path: "/logout",
    logout: true,
    name: "Sair",
    icon: (<FaSignOutAlt size="16px" />),
    component: Upgrade,
    layout: "/admin",
  },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   icon: "nc-icon nc-circle-09",
  //   component: UserProfile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-paper-2",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
