import React from 'react';

import { Form } from "react-bootstrap";
import { Container, FormBlock, Header, Title, Body, Footer } from './styles';

import ButtonCustom from '../../../components/ButtonCustom'
import Modal from '../../../components/Modal'

function CreateUser({
    onSubmit,
    handleEmail,
    email,
    handleModal,
    showModal
}) {
    return (
        <Container
            onClick={(e) => {
                handleModal(!showModal)
            }}
        >
            <Modal size="SM" onClick={(e) => {
                e.stopPropagation();
            }}>
                <FormBlock>
                    <Header>
                        <Title>Criar Usuário</Title>
                    </Header>
                    <Body>
                        <Form.Group>
                            <label for="email">
                                Email
                            </label>
                            <Form.Control
                                id="email"
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={(e) => handleEmail(e.target.value)}
                            ></Form.Control>
                        </Form.Group>
                    </Body>
                    <Footer>
                        <ButtonCustom
                            className="btn-fill pull-right"
                            type="submit"
                            variant="info"
                            color="#000"
                            onClick={onSubmit}
                        >
                            Criar
                        </ButtonCustom>
                        <ButtonCustom
                            className="btn-fill pull-right"
                            type="submit"
                            variant="info"
                            color="#fff"
                            textColor="#000"
                            border="#000"
                            onClick={() => handleModal(!showModal)}
                        >
                            Fechar
                        </ButtonCustom>
                    </Footer>
                </FormBlock>
            </Modal>
        </Container>
    );
}

export default CreateUser;