import styled from 'styled-components';

const Modal = styled.div`
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 40%;
    height: ${props => props.size && props.size == 'SM' ? '40%' : '70%'};

    border-radius: 10px;

    @media(max-width: 560px) {
        width: 80%;
        height: ${props => props.size && props.size == 'SM' ? '40%' : '80%'};
    }

    @media(max-width: 960px) and (min-width: 561px) {
        width: 50%;
        height: ${props => props.size && props.size == 'SM' ? '40%' : '70%'};
    }
`;

export default Modal;