import actionsTypes from '../../actionsTypes/auth';

const INITIAL_STATE = {
  user: {},
  endpoints: [],
  endpointSelected: null,
};

function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.HANDLEUSER:
      return {
        ...state,
        user: action.user,
      };
    case actionsTypes.HANDLEENDPOINTS:
      return {
        ...state,
        endpoints: action.endpoints,
      };
    case actionsTypes.HANDLEENDPOINTSELECTED:
      return {
        ...state,
        endpointSelected: action.endpoint,
      };
    default:
      return state;
  }
}

export default auth;
