import styled from 'styled-components';
import {
    Button,
} from "react-bootstrap";

const ButtonCustom = styled(Button)`
  background-color: ${props => props.color} !important;
  border-color: ${props => props.border ? props.border : props.color} !important;
  &:active:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 50%) !important;
  }

  color: ${props => props.textColor ? props.textColor : "#fff"} !important;

`;
export default ButtonCustom;
