import styled from 'styled-components';

export const ButtonBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 10px;
`

export const PaginationBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    a {
        color: black !important;
    }

    a:active:focus {
        box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 50%) !important;
    }

    li.active {
        span{
            background-color: black !important;
            border-color: black !important;
        }
    }
`