import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.5);
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export const FormBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

export const Header = styled.div`
    height: 20%;
    width: 80%;
`

export const Title = styled.p`
    font-size: 28px;
    padding-top: 40px;
`

export const Body = styled.div`
    height: 60%;
    width: 80%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Footer = styled.div`
    width: 80%;
    height: 20%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
        width: 45%;
        height: 50px;
    }
`

