import React, { Component } from "react";

import {  } from "react-bootstrap";

function FixedPlugin({
}) {
  return (
    <></>
  );
}

export default FixedPlugin;
