import actionsTypes from '../../actionsTypes/auth';

export function handleUser(user) {
  return {
    type: actionsTypes.HANDLEUSER,
    user,
  };
}

export function handleEndpoints(endpoints) {
  return {
    type: actionsTypes.HANDLEENDPOINTS,
    endpoints,
  };
}

export function handleEndpointSelected(endpoint) {
  return {
    type: actionsTypes.HANDLEENDPOINTSELECTED,
    endpoint,
  };
}
