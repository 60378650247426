import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ChartistGraph from "react-chartist";
import ChartistTooltip from "chartist-plugin-tooltips-updated";

import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { useDispatch, useSelector } from 'react-redux';
import { handleEndpoints, handleEndpointSelected } from '../../store/actions/auth';
import { apiAnalytics } from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.css";

function Dashboard() {
  // /users/active/endpoint?endpoint=3faae19c-65e0-4898-a5a7-93433d4249e5&adminId=e357d9d0-2c7a-4818-b752-33f93d159fcf
  const endpointSelected = useSelector(state => state.auth.endpointSelected)
  const [state, setState] = useState({
    totalUsers: 0,
    users: []
  });

  const [totalValue, setTotalValue] = useState(0);

  const [traccar, setTraccar] = useState({
    totalUsersTraccar: 0,
    totalDevicesTraccar: 0,
    qtdByData: {
      label: [],
      data: []
    },
    qtdByStatus: {
      label: [],
      data: []
    },
  });

  const optionsBarChart = {
    plugins: [
      ChartistTooltip({
        appendToBody: true
      })
    ],
    seriesBarDistance: 10,
    axisX: {
      showGrid: false,
    },
    height: "245px",
  };

  const handleData = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const { id } = userData;

      const response = await apiAnalytics.get('/users/active/endpoint', {
        params: {
          endpoint: endpointSelected,
          adminId: id
        }
      });

      const data = response.data;

      setState({
        ...data
      })

      const traccarResponse = await apiAnalytics.get('/endpoint', {
        params: {
          endpoint: endpointSelected,
          adminId: id
        }
      });

      const dataTraccar = traccarResponse.data;

      const chartBarData = Object.keys(dataTraccar.qtdByData).map((element) => {
        return {
          meta: 'Quantidade',
          value: dataTraccar.qtdByData[element].length
        };
      })

      console.log('chartBarData', chartBarData);

      const pieBarData = Object.keys(dataTraccar.qtdByStatus).map((element) => {
        return {
          meta: 'Quantidade',
          value: dataTraccar.qtdByStatus[element].length
        };
      })


      setTraccar({
        totalUsersTraccar: dataTraccar.totalUsers,
        totalDevicesTraccar: dataTraccar.totalDevices,
        qtdByData: {
          label: Object.keys(dataTraccar.qtdByData),
          data: [...chartBarData]
        },
        qtdByStatus: {
          label: Object.keys(dataTraccar.qtdByStatus),
          data: [...pieBarData]
        }
      })

      const billingResponse = await apiAnalytics.get('/endpoint/billing', {
        params: {
          endpoint: endpointSelected,
          adminId: id
        }
      });

      const dataBilling = billingResponse.data;

      setTotalValue(dataBilling.totalValue / 100);

    } catch (e) {
      toast.error('Houve um erro ao buscar as informaçõess da plataforma 😢')
    }
  }

  useEffect(() => {
    if (endpointSelected != null) {
      handleData();
    }
  }, [endpointSelected])

  const { totalUsers } = state;
  const { totalUsersTraccar, totalDevicesTraccar } = traccar;
  return (
    <>
      <ToastContainer />
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-mobile-alt text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Quantidade</p>
                      <Card.Title as="h4">{totalUsers}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-mobile-alt mr-1"></i>
                  Usuários Aplicativo
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-dollar-sign text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total</p>
                      <Card.Title as="h4">R$ {(totalValue).toFixed(2).replace('.', ',')}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-dollar-sign mr-1"></i>
                  Valor estimado
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-users text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Quantidade</p>
                      <Card.Title as="h4">{totalUsersTraccar}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-users mr-1"></i>
                  Usuários Plataforma
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-car text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Quantidade</p>
                      <Card.Title as="h4">{totalDevicesTraccar}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-car mr-1"></i>
                  Dispositivos Ativos
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Período</Card.Title>
                <p className="card-category">Conexão dos dispositivos da plataforma</p>
              </Card.Header>
              <Card.Body>
                <div className="ct-chart" id="chartActivity">
                  <ChartistGraph
                    data={{
                      labels: traccar.qtdByData.label,
                      series: [
                        [...traccar.qtdByData.data]
                      ],
                    }}
                    type="Bar"
                    options={optionsBarChart}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          seriesBarDistance: 5,
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="4">
            <Card style={{ display: 'block' }}>
              <Card.Header>
                <Card.Title as="h4">Status</Card.Title>
                <p className="card-category">Dispositivos por status</p>
              </Card.Header>
              <Card.Body>
                <div
                  className="ct-chart ct-perfect-fourth"
                  id="chartPreferences"
                >
                  <ChartistGraph
                    data={{
                      labels: traccar.qtdByStatus.label,
                      series: [...traccar.qtdByStatus.data]
                    }}
                    type="Pie"

                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
