import React, { useEffect } from "react";
import { useHistory, useLocation, NavLink } from "react-router-dom";

import { Nav, Form } from "react-bootstrap";
import { NavItemBlock, CustomTitle, Logout, SelectBlock } from "./styles";

import { useSelector, useDispatch } from 'react-redux'
import { handleEndpoints, handleEndpointSelected } from '../../store/actions/auth';

function Sidebar({ color, image, routes }) {
  const dispatch = useDispatch();

  const endpoints = useSelector(state => state.auth.endpoints)
  const endpointSelected = useSelector(state => state.auth.endpointSelected)

  const handleEndpoint = (e) => {
    const endpoint = e.target.value;
    localStorage.setItem("endpointSelected", endpoint)
    dispatch(handleEndpointSelected(endpoint))
  }

  const loadUserData = () => {
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const endpointSelected = localStorage.getItem('endpointSelected');

      const { endpoints } = userData;

      dispatch(handleEndpoints(endpoints));

      if(endpointSelected !== null) {
        dispatch(handleEndpointSelected(endpointSelected));
      } else if(endpoints.lenght > 0) {
        dispatch(handleEndpointSelected(endpoints[0].id));
      }
    } catch (e) {
      console.log('Error', e);
    }
    
  }

  useEffect(() => {
    loadUserData();
  }, [])

  const location = useLocation();
  const history = useHistory();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <a
            href="https://www.creative-tim.com?ref=lbd-sidebar"
            className="simple-text logo-mini mx-1"
          >
            <div className="logo-img">
              <img
                src={require("assets/img/reactlogo.png").default}
                alt="..."
              />
            </div>
          </a>
          <CustomTitle>
            <a className="simple-text" href="https://www.rastreou.com.br/">
              RASTREOU
            </a>
          </CustomTitle>
        </div>
        <SelectBlock>
          <Form.Control
            as="select"
            custom
            className="nav-link"
            onChange={handleEndpoint}
            value={endpointSelected}
          >
            {endpoints.map((element) => {
              return (<option value={element.id}>{element.name}</option>)
            })}
          </Form.Control>
        </SelectBlock>
        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li
                  className={
                    prop.logout
                      ? "active active-pro"
                      : activeRoute(prop.layout + prop.path)
                  }
                  key={key}
                >
                  {!prop.logout ? (<NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <NavItemBlock>
                      {prop.icon}
                      <p>{prop.name}</p>
                    </NavItemBlock>
                  </NavLink>) : (<Logout
                    className="nav-link"
                    activeClassName="active"
                    onClick={() => {
                      localStorage.removeItem('userData')
                      history.push('/login')
                    }}
                  >
                    <NavItemBlock>
                      {prop.icon}
                      <p>{prop.name}</p>
                    </NavItemBlock>
                  </Logout>)}
                </li>
              );
            return null;
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
