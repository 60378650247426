import styled from 'styled-components';

export const NavItemBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 5px 0;
    p {
        margin: 0;
        padding-left: 5px;
        line-height: 31px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        display: inline-flex;
    }
`;

export const CustomTitle = styled.div`
  padding-left: 15px;
  padding-top: 3px;
`;

export const Logout = styled.div`
  cursor: pointer;
`

export const SelectBlock = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 10px;

  select {
    width: 90%;
    background-color: transparent;
    color: white
  }

  select option {
    color: #000;
  }
`