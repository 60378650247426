import React, { useState } from 'react';

import { Form } from "react-bootstrap";
import { FaInstagram, FaLinkedin, FaAt } from 'react-icons/fa'
import { Container, FormBlock, Header, Body, Footer } from './styles';
import { apiAdmin } from '../../services/api'
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'

import logoDark from '../../assets/img/logo.png'
import ButtonCustom from '../../components/ButtonCustom'

import 'react-toastify/dist/ReactToastify.css';

function LoginPage() {
    const history = useHistory();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const onSubmitLogin = async () => {
        try {
            const response = await apiAdmin.post('/admin/login', {
                email,
                password
            })
            const user = response.data;
            delete user.password;
            localStorage.setItem('userData', JSON.stringify(user));
            history.push('/admin/dashboard')
        } catch (e) {
            console.log('Erro ao logar', e);
            toast.error("Houve um erro ao logar 😢")
        }
    }

    return (
        <Container>
            <FormBlock>
                <Header>
                    <img src={logoDark} ></img>
                </Header>
                <Body>
                    <Form.Group>
                        <label for="email">
                            Email
                        </label>
                        <Form.Control
                            id="email"
                            placeholder="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <label for="password">
                            Senha
                        </label>
                        <Form.Control
                            id="password"
                            placeholder="Senha"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        ></Form.Control>
                    </Form.Group>
                    <ButtonCustom
                        className="btn-fill pull-right"
                        type="submit"
                        variant="info"
                        color="#000"
                        onClick={onSubmitLogin}
                    >
                        Entrar
                    </ButtonCustom>
                </Body>
                <Footer>
                    <FaInstagram size="20px" color="#616161" />
                    <FaLinkedin size="20px" color="#616161" />
                    <FaAt size="20px" color="#616161" />
                </Footer>
            </FormBlock>
            <ToastContainer />
        </Container>
    );
}

export default LoginPage;