import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux'

import store from './store'

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "layouts/Admin.js";
import LoginPage from "./pages/LoginPage";

function PrivateRoute({ component: Component, ...rest }) {
  const user = localStorage.getItem('userData')
  return (
    <Route
      {...rest}
      render={props =>
        user != null ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function LoginRoute({ component: Component, ...rest }) {
  const user = localStorage.getItem('userData')
  return (
    <Route
      {...rest}
      render={props =>
        user != null ? (
          <Redirect
            to={{
              pathname: "/admin/dashboard",
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <LoginRoute path="/login" component={LoginPage} />
        <PrivateRoute path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  </Provider>,

  document.getElementById("root")
);
