import styled from 'styled-components';
import image from '../../assets/img/login_background.png'

export const Container = styled.div`
  background-image: url(${image});
    
  width: 100vw;
  height: 100vh;
  background-size: cover;
  

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormBlock = styled.div`
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 40%;
    height: 70%;

    border-radius: 10px;

    @media(max-width: 560px) {
        width: 80%;
        height: 80%;
    }

    @media(max-width: 960px) and (min-width: 561px) {
        width: 50%;
        height: 70%;
    }
`;

export const Header = styled.div`
    width: 80%;
    height: 20%;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: 80%;
        margin-top: 30px;
    }
`

export const Body = styled.div`
    padding-top: 20px;
    width: 80%;
    height: 60%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
        width: 100%;
    }
`

export const Footer = styled.div`
    width: 40%;
    height: 20%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
